import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const loginApi = createApi({
  reducerPath: "login",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.URL,
  }),
  endpoints(builder) {
    return {
      adminConnect: builder.mutation({
        query: (credentials) => {
          return {
            url: "/api/users/v_1/signin",
            method: "POST",
            body: {
              username: credentials.username,
              password: credentials.password,
            },
          };
        },
      }),

      studentConnect: builder.mutation({
        query: ({ key }) => {
          return {
            url: "/api/users/v_2/parent/signin",
            method: "POST",
            body: {
              key,
            },
          };
        },
      }),
    };
  },
});

export const { useAdminConnectMutation, useStudentConnectMutation } = loginApi;
export { loginApi };
