export const MAIN_LAYOUT = 1;
export const ADMIN_LOGIN_LAYOUT = 2;
export const TEACHER_LOGIN_LAYOUT = 3;
export const STUDENT_LOGIN_LAYOUT = 4;
export const FORGET_PASSWORD_LAYOUT = 5;

export const ADMIN_ACCOUNT_DATA = {
  layout: ADMIN_LOGIN_LAYOUT,
  title: "Administrateur",
  text: "Se connecter à mon compte administrateur.",
  image: "/auth/assets/admin_login.png",
};

export const TEACHER_ACCOUNT_DATA = {
  layout: TEACHER_LOGIN_LAYOUT,
  title: "Enseignant",
  text: "Je veux utiliser Scholine avec mes élèves.",
  image: "/auth/assets/parent_login.png",
};

export const STUDENT_ACCOUNT_DATA = {
  layout: STUDENT_LOGIN_LAYOUT,
  title: "Elève",
  text: "Mon enseignant m’a demandé de pratiquer avec Scholine.",
  image: "/auth/assets/parent_login.png",
};
