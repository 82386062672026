import React from "react";
import AccountTypes from "./AccountTypes";
import {
  ADMIN_ACCOUNT_DATA,
  STUDENT_ACCOUNT_DATA,
  TEACHER_ACCOUNT_DATA,
} from "../utils/constants";

export default function MainLayout() {
  return (
    <section>
      <div className="container mx-auto flex flex-col space-y-6 items-center md:flex-row md:space-x-12 md:space-y-0">
        <AccountTypes account={ADMIN_ACCOUNT_DATA} />
        <AccountTypes account={TEACHER_ACCOUNT_DATA} />
        <AccountTypes account={STUDENT_ACCOUNT_DATA} />
      </div>
    </section>
  );
}
