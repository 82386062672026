import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateLayout } from "../store/slices/layouts";
import { MAIN_LAYOUT, STUDENT_ACCOUNT_DATA } from "../utils/constants";
import { useStudentConnectMutation } from "../store";

export default function StudentLayout() {
  const dispatch = useDispatch();

  const [studentConnect, studentConnectResult] = useStudentConnectMutation();

  const onLayoutChange = () => {
    dispatch(updateLayout({ layout: MAIN_LAYOUT }));
  };

  const [keyValue, setKeyValue] = useState("");

  const handleInputChange = (event) => {
    setKeyValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    studentConnect({ key: keyValue });
  };

  if (studentConnectResult.isLoading) {
    console.log("loading");
  }

  if (studentConnectResult.data) {
    console.log("data");
    console.log(studentConnectResult.data);
  }

  return (
    <div className="container flex flex-col space-y-6 mx-auto md:flex-row md:space-y-0 md:space-x-12">
      <div className="md:block md:w-1/2">
        <img src={STUDENT_ACCOUNT_DATA.image} alt="" />
      </div>

      <div className="flex flex-col items-center space-y-3 md:w-1/2">
        <div>
          <span
            onClick={onLayoutChange}
            className="text-brightRed cursor-pointer back-btn hover:text-darkGray animate-pulse"
          >
            Retour au mode de connexion ?
          </span>
        </div>

        <div className="max-w-sm flex flex-col mx-auto py-6 px-4 rounded-3xl bg-brightRedLight space-y-3 shadow-2xl">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col space-y-3">
              <input
                type="text"
                name="key"
                placeholder="Code d'activation"
                value={keyValue}
                onChange={handleInputChange}
                className="px-5 py-2 rounded-full focuse:outline-none"
              />
              <button
                className="px-6 py-2 text-white rounded-full bg-brightRed hover:bg-gray-900"
                type="submit"
              >
                Connecter
              </button>
            </div>
          </form>
          <div className="py-6 px-4 rounded-3xl bg-white hidden">
            <ul className="list-disc"></ul>
          </div>
        </div>
      </div>
    </div>
  );
}
